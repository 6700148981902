import * as React from "react"
import Grid from "@material-ui/core/Grid"
import Field from "../auth/field"
import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles(theme => ({
  mainContainer: { width: "100%", marginTop: "1rem" },
  accountIcon: { fontSize: "4rem !important" },
  fieldGrid: { marginBottom: "1rem" },

  formStyle: {
    minWidth: "100%",
  },
}))

const CartOrderNotesField = ({ control, orderOptions }) => {
  const classes = useStyles()

  return (
    <Grid
      item
      container
      direction="row"
      justifyContent="center"
      classes={{ root: classes.mainContainer }}
    >
      <Grid item classes={{ root: classes.formStyle }}>
        <Grid item classes={{ root: classes.fieldGrid }}>
          <Field
            fieldDefaultValue={orderOptions.orderNotes}
            fieldName={"orderNotes"}
            fieldControl={control}
            multiline={true}
            maxRows={4}
            fieldRules={{
              maxLength: {
                value: 200,
                message: "Order notes is too long",
              },
            }}
            fieldLabel={"Order notes"}
            fieldHelperText={"200 max characters"}
          ></Field>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default CartOrderNotesField
