import * as React from "react"
import axios from "axios"
import { Elements } from "@stripe/react-stripe-js"
import { loadStripe } from "@stripe/stripe-js"
import { v4 as uuidv4 } from "uuid"
import Box from "@material-ui/core/Box"
import CircularProgress from "@material-ui/core/CircularProgress"

import handleError from "../../helperFunc/handleError"
import { makeStyles } from "@material-ui/core/styles"
import CheckoutForm from "./CheckoutForm"

const stripePromise = loadStripe(process.env.GATSBY_STRIPE_PK)

const useStyles = makeStyles(theme => ({
  outBox: {
    width: "100%",
    minHeight: "calc(100vh  - 66px)",
    position: "relative",
    overflowY: "scroll",
  },

  inBox: {
    width: "80%",
    textAlign: "center",
    position: "absolute",
    left: "50%",
    top: "50%",
    msTransform: "translate(-50%, -50%)",
    transform: "translate(-50%, -50%)",
  },
}))

const CheckoutPanel = ({
  user,
  dispatchUser,
  defaultUser,
  cart,
  dispatchCart,
  dispatchFeedback,
  feedback,
  storeSettings,
  dispatchOrderOptions,
  order,
}) => {
  const [clientSecret, setClientSecret] = React.useState("")
  const [existingCards, setExistingCards] = React.useState([])
  const [isLoading, setIsLoading] = React.useState(true)

  const classes = useStyles()

  React.useEffect(() => {
    // Create PaymentIntent as soon as the page loads
    setIsLoading(true)
    setClientSecret("")
    if (order) {
      const storedIntent = localStorage.getItem("intentID")
      const idempotencyKey = uuidv4()

      axios
        .post(
          process.env.GATSBY_STRAPI_URL + "/api/orders/process",
          {
            orderId: order.id,
            total: order.total,
            idempotencyKey: idempotencyKey,
            storedIntent: storedIntent,
          },
          {
            headers: {
              Authorization: `Bearer ${user.jwt}`,
            },
          }
        )
        .then(response => {
          setClientSecret(response.data.client_secret)
          localStorage.setItem("intentID", response.data.intentID)

          setExistingCards([])
          axios
            .get(
              process.env.GATSBY_STRAPI_URL + "/api/orders/getPaymentMethods",
              {
                headers: {
                  Authorization: `Bearer ${user.jwt}`,
                },
              }
            )
            .then(response => {
              setExistingCards(response.data)
              setIsLoading(false)
            })
            .catch(error => {
              handleError(error, dispatchFeedback)
              setIsLoading(false)
            })
        })
        .catch(error => {
          handleError(error, dispatchFeedback)
          setIsLoading(false)
        })
    }
  }, [order, user.jwt, dispatchFeedback, setExistingCards])

  const appearance = {
    theme: "flat",
    variables: {
      colorPrimary: "#000000",
      colorBackground: "#f5f5f5",
      borderRadius: "5px",
    },
    rules: {
      ".Block": {
        padding: "12px",
      },
      ".Tab--selected, .Tab--selected:focus, .Tab--selected:hover": {
        boxShadow: "0px 0px 0px 2px",
      },
      ".Input": {
        padding: "12px",
      },
      ".Input:focus": {
        boxShadow: "0px 0px 0px 2px",
      },
      ".Label": {
        fontSize: 0,
      },
    },
  }

  const options = {
    clientSecret,
    appearance,
    loader: "always",
  }

  return clientSecret && !isLoading ? (
    <Elements options={options} stripe={stripePromise}>
      <CheckoutForm
        clientSecret={clientSecret}
        order={order}
        user={user}
        dispatchUser={dispatchUser}
        dispatchFeedback={dispatchFeedback}
        storeSettings={storeSettings}
        dispatchCart={dispatchCart}
        dispatchOrderOptions={dispatchOrderOptions}
        setClientSecret={setClientSecret}
        feedback={feedback}
        existingCards={existingCards}
      />
    </Elements>
  ) : (
    <Box classes={{ root: classes.outBox }}>
      <Box classes={{ root: classes.inBox }}>
        <CircularProgress color="primary" />
      </Box>
    </Box>
  )
}

export default CheckoutPanel
