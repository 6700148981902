import * as React from "react"
import Typography from "@material-ui/core/Typography"
import Alert from "@material-ui/lab/Alert"
import Button from "@material-ui/core/Button"

import { clearCart, clearUnAvailable } from "../../contexts/actions"

import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles(theme => ({
  alert: {
    marginBottom: "1rem",
  },
  alertBut: {
    alignSelf: "center",
  },

  alertButText: {
    whiteSpace: "nowrap",
    fontSize: "0.8rem",
  },

  "@global": {
    body: {
      [theme.breakpoints.down("sm")]: {
        backgroundColor: `${theme.palette.common.white} !important`,
      },
    },
  },
}))

const CartAlert = ({
  cart,
  dispatchCart,
  cartAlert,
  setIsContButDisable,
  setCartAlert,
  storeSettings,
}) => {
  const [stockAlert, setStockAlert] = React.useState(false)
  const [stockMsg, setStockMsg] = React.useState("")

  const classes = useStyles()

  React.useEffect(() => {
    let disabledBut = false
    cart.map((item, index) => {
      if (!item.isAvailable) {
        disabledBut = true
      }
      return true
    })

    if (disabledBut === true) {
      let filtArray = cart.map(item =>
        !item.isAvailable ? ` ${item.name}` : ``
      )
      filtArray = filtArray.filter(item => item !== "")
      setStockMsg(
        `Unfortunately the following items are not available: ${filtArray}, please remove them from your cart before proceeding.`
      )
      setStockAlert(true)
    } else {
      setStockAlert(false)
      setStockMsg("")
    }
    setIsContButDisable(disabledBut)
  }, [cart, setIsContButDisable])

  return (
    <>
      {storeSettings.orderWindows.length < 1 &&
      storeSettings.isResOutOfHours ? (
        <Alert severity="error" classes={{ root: classes.alert }}>
          <Typography variant={"body1"}>
            {
              "Unfortunately we are currently closed and unable to take your order."
            }
          </Typography>
        </Alert>
      ) : !storeSettings.isDineInClosed && storeSettings.isResOutOfHours ? (
        <Alert severity="error" classes={{ root: classes.alert }}>
          <Typography variant={"body1"}>
            {
              "Unfortunately our kitchen is now closed, and our Dine-In service is not available."
            }
          </Typography>
        </Alert>
      ) : (
        <></>
      )}
      {cartAlert && (
        <Alert
          classes={{ root: classes.alert }}
          severity="error"
          action={
            <Button
              classes={{ root: classes.alertBut }}
              color="inherit"
              size="small"
              variant="outlined"
              onClick={() => {
                dispatchCart(clearCart())
                setCartAlert(false)
              }}
            >
              <Typography
                variant="body1"
                classes={{ root: classes.alertButText }}
              >
                {"CLEAR CART"}
              </Typography>
            </Button>
          }
        >
          <Typography variant={"body1"}>
            {
              "Invalid cart, please clear the cart and try again, if error persists please contact support."
            }
          </Typography>
        </Alert>
      )}
      {stockAlert && (
        <Alert
          classes={{ root: classes.alert }}
          severity="error"
          action={
            <Button
              classes={{ root: classes.alertBut }}
              color="inherit"
              size="small"
              variant="outlined"
              onClick={() => {
                dispatchCart(clearUnAvailable())
              }}
            >
              <Typography
                variant="body1"
                classes={{ root: classes.alertButText }}
              >
                {"REMOVE ALL"}
              </Typography>
            </Button>
          }
        >
          <Typography variant={"body1"}>{stockMsg}</Typography>
        </Alert>
      )}
    </>
  )
}

export default CartAlert
