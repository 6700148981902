import * as React from "react"
import Grid from "@material-ui/core/Grid"
import InputAdornment from "@material-ui/core/InputAdornment"
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined"
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined"
import Field from "../auth/field"
import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles(theme => ({
  mainContainer: { marginBottom: 0 },
  accountIcon: { fontSize: "4rem !important" },
  fieldGrid: { marginBottom: "0.5rem" },
}))

const AccountDetailsFields = ({ user, control }) => {
  const classes = useStyles()

  return (
    <Grid
      item
      container
      direction="row"
      justifyContent="space-between"
      classes={{ root: classes.mainContainer }}
      spacing={2}
    >
      <Grid item classes={{ root: classes.fieldGrid }} xs={12} sm={6}>
        <Field
          fieldDefaultValue={user.userDisplayName}
          fieldName={"name"}
          fieldControl={control}
          fieldQuickCheck={true}
          fieldInstCheck={true}
          fieldInstHelperText={"Your name is required"}
          fieldRules={{
            required: {
              value: true,
              message: "Your name is required",
            },
            maxLength: {
              value: 70,
              message: "Name is too long",
            },
            minLength: {
              value: 2,
              message: "Name is too short",
            },
          }}
          fieldPlaceholder={"Name *"}
          fieldStartAdornment={
            <InputAdornment position="start">
              <AccountCircleOutlinedIcon />
            </InputAdornment>
          }
        ></Field>
      </Grid>
      <Grid item classes={{ root: classes.fieldGrid }} xs={12} sm={6}>
        <Field
          fieldDefaultValue={user.receiptEmail}
          fieldName={"receiptEmail"}
          fieldControl={control}
          fieldRules={{
            validate: {
              value: value => {
                const emailRegex = new RegExp(/^\S+@\S+\.\S+$/)
                if (emailRegex.test(value.replace(/^\s+|\s+$/gm, ""))) {
                  return true
                } else if (value.length < 1) {
                  return true
                } else {
                  return "Invalid email"
                }
              },
            },
            maxLength: {
              value: 320,
              message: "Email address is too long",
            },
          }}
          fieldPlaceholder={"Email (optional)"}
          fieldHelperText={"Get your receipts by email"}
          fieldStartAdornment={
            <InputAdornment position="start">
              <EmailOutlinedIcon />
            </InputAdornment>
          }
        ></Field>
      </Grid>
    </Grid>
  )
}

export default AccountDetailsFields
