import * as React from "react"
import clsx from "clsx"
import Grid from "@material-ui/core/Grid"
import Box from "@material-ui/core/Box"
import IconButton from "@material-ui/core/IconButton"
import DialogContent from "@material-ui/core/DialogContent"
import DialogTitle from "@material-ui/core/DialogTitle"
import ToggleButton from "@material-ui/lab/ToggleButton"
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup"
import Typography from "@material-ui/core/Typography"
import toTime from "../../helperFunc/toTime"

import { makeStyles } from "@material-ui/core/styles"
import ArrowBackIosOutlinedIcon from "@mui/icons-material/ArrowBackIosOutlined"
import ArrowForwardIosOutlinedIcon from "@mui/icons-material/ArrowForwardIosOutlined"

const useStyles = makeStyles(theme => ({
  dialogTitCont: {
    padding: "1rem",
  },
  dialogTitle: { fontSize: "1.2rem !important", marginTop: "0rem" },
  selectedBut: {
    backgroundColor: `${theme.palette.secondary.light} !important`,
    color: `${theme.palette.common.white} !important`,
  },
  dialogContent: {
    minHeight: "5rem",
    backgroundColor: theme.palette.common.white,
    padding: 0,
  },
  dialogContentInsideBox: {
    paddingLeft: "1rem",
    paddingRight: "1rem",
  },
  butText: {
    paddingLeft: "0.5rem",
    paddingRight: "0.5rem",
    fontSize: "1rem",
  },
  togButs: {
    backgroundColor: theme.palette.common.offWhite,
  },
  standardBut: {
    paddingTop: "1rem !important",
    paddingBottom: "1rem !important",
  },
  togButDisabled: {
    backgroundColor: `${theme.palette.common.almostWhite} !important`,
  },

  butTextNotSelected: {
    color: `${theme.palette.common.grey} !important`,
  },
  butTextNotSelectedDisabled: {
    color: `${theme.palette.common.seventyWhite} !important`,
  },

  timeGrid: {
    marginTop: "1rem",
  },
  gridOne: {
    textAlign: "left",
  },
  gridTwo: {
    textAlign: "center",
  },
  gridThree: {
    textAlign: "right",
  },

  dateButDisabled: {
    color: `${theme.palette.common.offWhite} !important`,
    backgroundColor: `${theme.palette.common.almostWhite} !important`,
  },
  dateBut: {
    backgroundColor: theme.palette.common.almostWhite,
    padding: "0.5rem",

    [theme.breakpoints.up("md")]: {
      "&:hover": {
        backgroundColor: theme.palette.common.offWhite,
      },
    },
  },

  slotsGrid: {
    minHeight: "5rem",
  },

  slotsText: {
    fontSize: "1rem",
    textTransform: "none",
  },
  slotsTextBold: {
    fontSize: "1rem",
    textTransform: "none",
    fontWeight: 700,
    marginLeft: "0.4rem",
  },
  slotBut: {
    width: "100% !important",
    border: "1px solid rgba(0, 0, 0, 0.7) !important",
    paddingTop: "1rem !important",
    paddingBottom: "1rem !important",
    backgroundColor: `${theme.palette.common.white} !important`,
    color: `${theme.palette.primary.main} !important`,
  },
  slotButSelected: {
    backgroundColor: `${theme.palette.secondary.light} !important`,
    color: `${theme.palette.common.white} !important`,
    border: "1px solid rgba(255, 255, 255, 0.7) !important",
  },

  topShaddow: {
    position: "sticky",
    zIndex: 1,
    top: 0,
    background: "linear-gradient(180deg, #00000030, transparent)",
    height: "1rem",
  },
  bottomShaddow: {
    position: "sticky",
    zIndex: 1,
    bottom: 0,
    background: "linear-gradient(0deg, #00000030, transparent)",
    height: "1rem",
  },
}))

const CartDialogContent = ({
  storeSettings,
  selectedWindow,
  setSelectedWindow,
  selectedTable,
  setSelectedTable,
  orderType,
  setOrderType,
  orderOptions,
}) => {
  const [windowArrayIndex, setWindowArrayIndex] = React.useState(0)

  const classes = useStyles()

  const handleChange = (event, newOrderType) => {
    if (newOrderType !== null) {
      setOrderType(newOrderType)
    }
  }

  React.useEffect(() => {
    if (
      selectedWindow.dateLabel &&
      selectedWindow.date &&
      selectedWindow.time
    ) {
      let newIndex = storeSettings.orderWindows.findIndex(obj => {
        return obj.date.label === selectedWindow.dateLabel
      })
      if (newIndex >= 0) {
        setWindowArrayIndex(newIndex)
      }
    }
  }, [
    selectedWindow.date,
    selectedWindow.dateLabel,
    selectedWindow.time,
    storeSettings.orderWindows,
  ])

  return (
    <>
      <DialogTitle
        id="order-details-title"
        classes={{ root: classes.dialogTitCont }}
      >
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          <Grid item>
            <ToggleButtonGroup
              color="standard"
              value={orderType}
              exclusive
              onChange={handleChange}
              classes={{ root: classes.togButs }}
            >
              <ToggleButton
                disabled={storeSettings.isTakeAwayClosed}
                value="takeAway"
                classes={{
                  root: classes.standardBut,
                  selected: classes.selectedBut,
                  disabled: classes.togButDisabled,
                }}
              >
                <Typography
                  variant="body2"
                  classes={{
                    root: clsx(classes.butText, {
                      [classes.butTextNotSelected]: orderType !== "takeAway",
                      [classes.butTextNotSelectedDisabled]:
                        storeSettings.isTakeAwayClosed,
                    }),
                  }}
                >
                  {"Take Away"}
                </Typography>
              </ToggleButton>
              {!storeSettings.isDineInClosed && (
                <ToggleButton
                  disabled={
                    storeSettings.isResOutOfHours ||
                    isNaN(parseInt(orderOptions.orderTableNumber))
                  }
                  value="dineIn"
                  classes={{
                    root: classes.standardBut,
                    selected: classes.selectedBut,
                    disabled: classes.togButDisabled,
                  }}
                >
                  <Typography
                    variant="body2"
                    classes={{
                      root: clsx(classes.butText, {
                        [classes.butTextNotSelected]: orderType !== "dineIn",
                        [classes.butTextNotSelectedDisabled]:
                          storeSettings.isResOutOfHours,
                      }),
                    }}
                  >
                    {"Dine-In"}
                  </Typography>
                </ToggleButton>
              )}
            </ToggleButtonGroup>
          </Grid>
        </Grid>
        {orderType === "takeAway" && (
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            classes={{ root: classes.timeGrid }}
          >
            <Grid item xs classes={{ root: classes.gridOne }}>
              <IconButton
                aria-label="dayBack"
                color="primary"
                onClick={() => {
                  if (windowArrayIndex > 0) {
                    setWindowArrayIndex(windowArrayIndex - 1)
                  }
                }}
                disabled={windowArrayIndex < 1 ? true : false}
                classes={{
                  root: classes.dateBut,
                  disabled: classes.dateButDisabled,
                }}
              >
                <ArrowBackIosOutlinedIcon />
              </IconButton>
            </Grid>
            <Grid item xs={8} classes={{ root: classes.gridTwo }}>
              <Typography variant="body2">
                {`${
                  storeSettings.orderWindows[windowArrayIndex].dayLabel
                    ? `${storeSettings.orderWindows[windowArrayIndex].dayLabel}`
                    : `${storeSettings.orderWindows[windowArrayIndex].dayName}`
                }`}
              </Typography>
              <Typography variant="body1">
                {`${
                  storeSettings.orderWindows[windowArrayIndex].dayLabel
                    ? `${storeSettings.orderWindows[windowArrayIndex].dayName}`
                    : ""
                } ${storeSettings.orderWindows[windowArrayIndex].date.label}`}
              </Typography>
            </Grid>
            <Grid item xs classes={{ root: classes.gridThree }}>
              <IconButton
                aria-label="dayFront"
                variant="contained"
                color="primary"
                onClick={() => {
                  if (
                    windowArrayIndex <
                    storeSettings.orderWindows.length - 1
                  ) {
                    setWindowArrayIndex(windowArrayIndex + 1)
                  }
                }}
                disabled={
                  windowArrayIndex >= storeSettings.orderWindows.length - 1
                    ? true
                    : false
                }
                classes={{
                  root: classes.dateBut,
                  disabled: classes.dateButDisabled,
                }}
              >
                <ArrowForwardIosOutlinedIcon />
              </IconButton>
            </Grid>
          </Grid>
        )}
      </DialogTitle>
      <DialogContent classes={{ root: classes.dialogContent }}>
        <Box classes={{ root: classes.topShaddow }}></Box>
        <Box classes={{ root: classes.dialogContentInsideBox }}>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            classes={{ root: classes.slotsGrid }}
            spacing={1}
          >
            {orderType === "takeAway" ? (
              storeSettings.orderWindows[windowArrayIndex].slots.map(
                (slot, index) => {
                  return (
                    <Grid key={index} item xs={6}>
                      <ToggleButton
                        color="standard"
                        classes={{
                          root: classes.slotBut,
                          selected: classes.slotButSelected,
                        }}
                        value={{
                          dateLabel:
                            storeSettings.orderWindows[windowArrayIndex].date
                              .label,
                          date: storeSettings.orderWindows[windowArrayIndex]
                            .date,
                          time: slot,
                        }}
                        selected={
                          selectedWindow.dateLabel ===
                            storeSettings.orderWindows[windowArrayIndex].date
                              .label && selectedWindow.time === slot
                        }
                        onChange={() => {
                          setSelectedWindow({
                            dateLabel:
                              storeSettings.orderWindows[windowArrayIndex].date
                                .label,
                            date: storeSettings.orderWindows[windowArrayIndex]
                              .date,
                            time: slot,
                            surcharge:
                              storeSettings.orderWindows[windowArrayIndex]
                                .surcharge,
                          })
                        }}
                      >
                        <Typography
                          variant="body2"
                          classes={{ root: classes.slotsText }}
                        >
                          {toTime(slot)}
                        </Typography>
                      </ToggleButton>
                    </Grid>
                  )
                }
              )
            ) : orderType === "dineIn" ? (
              storeSettings.tableList.map((table, index) => {
                return (
                  <Grid key={index} item xs={6}>
                    <ToggleButton
                      color="standard"
                      classes={{
                        root: classes.slotBut,
                        selected: classes.slotButSelected,
                      }}
                      value={table}
                      selected={selectedTable === table}
                      onChange={() => {
                        setSelectedTable(table)
                      }}
                    >
                      <Typography
                        variant="body2"
                        classes={{ root: classes.slotsText }}
                      >
                        {`Table `}
                      </Typography>
                      <Typography
                        variant="body2"
                        classes={{ root: classes.slotsTextBold }}
                      >
                        {`${table}`}
                      </Typography>
                    </ToggleButton>
                  </Grid>
                )
              })
            ) : (
              <></>
            )}
          </Grid>
        </Box>
        <Box classes={{ root: classes.bottomShaddow }}></Box>
      </DialogContent>
    </>
  )
}

export default CartDialogContent
