import * as React from "react"
import clsx from "clsx"
import Box from "@material-ui/core/Box"
import Button from "@material-ui/core/Button"
import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"

import Typography from "@material-ui/core/Typography"
import ButtonBase from "@material-ui/core/ButtonBase"
import IconButton from "@material-ui/core/IconButton"
import Chip from "@material-ui/core/Chip"
import CloseIcon from "@mui/icons-material/Close"
import CircularProgress from "@material-ui/core/CircularProgress"

import toTime from "../../helperFunc/toTime"
import CartDialogContent from "./CartDialogContent"
import { updateOrderOptions } from "../../contexts/actions"

import EditOutlinedIcon from "@mui/icons-material/EditOutlined"
import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles(theme => ({
  mainContainer: {
    backgroundColor: theme.palette.common.almostWhite,
    width: "100%",
    marginBottom: "1rem",
    padding: "0.8rem",
    justifyContent: "space-between",
    borderRadius: "0.5rem",
  },

  mainContainerLoading: {
    display: "flex",
    justifyContent: "center",
  },

  mainContainerError: {
    backgroundColor: theme.palette.common.aletBg,
  },
  tableBox: {
    display: "flex",
    alignItems: "center",
  },

  tableBold: {
    marginLeft: "0.4rem",
    fontWeight: 700,
  },

  butText: {
    fontSize: "1.2rem",
  },

  tableBoldTime: {
    marginLeft: 0,
    fontWeight: 700,
    lineHeight: "1.2rem",
    textAlign: "left",
  },
  tableNormTextTime: {
    textAlign: "left",
    lineHeight: "1.2rem",
  },

  errorText: {
    fontWeight: 500,
    color: theme.palette.common.alertIcon,
  },
  orderTypeChip: {
    cursor: "pointer",
  },
  orderTypeChipRed: {
    backgroundColor: theme.palette.common.alertIcon,
  },
  orderChoiceChip: {
    marginLeft: "0.5rem",
  },
  orderTypeText: {},
  editBut: {
    padding: "0.4rem",
    backgroundColor: theme.palette.common.white,
  },
  dialogTitCont: {
    paddingBottom: "1rem",
  },
  dialogTitle: { fontSize: "1.5rem !important", marginTop: "0rem" },
  dialogPaperFullWidth: {
    width: "calc(100% - 32px)",
  },
  dialogPaper: {
    margin: "16px",
  },
  dialogPaperScrollPaper: {
    maxHeight: "calc(100% - 128px)",
  },
  but: {
    margin: "0.5rem",
    paddingTop: "1rem",
    paddingBottom: "1rem",
  },
  dialogActionsArea: {},

  xBut: {
    backgroundColor: theme.palette.common.almostWhite,

    marginRight: "1.5rem",
    marginLeft: "1.5rem",
  },
}))

const CartOrderType = ({
  orderOptions,
  dispatchOrderOptions,
  isOrdOptError,
  setIsOrdOptError,
  storeSettings,
  textToShow,
  setTextToShow,
  open,
  setOpen,
}) => {
  const [orderType, setOrderType] = React.useState(null)
  const [selectedWindow, setSelectedWindow] = React.useState({
    dateLabel: null,
    date: null,
    time: null,
    surcharge: {
      phSurchargeRate: 0,
      sunSurchargeRate: 0,
      satSurchargeRate: 0,
    },
  })
  const [selectedTable, setSelectedTable] = React.useState(null)
  const [loadingOptions, setLoadingOptions] = React.useState(true)

  const classes = useStyles()
  const handleClose = () => {
    setOpen(false)
  }

  const surchargeZero = React.useMemo(() => {
    return {
      phSurchargeRate: 0,
      sunSurchargeRate: 0,
      satSurchargeRate: 0,
    }
  }, [])

  const updateOrderTable = React.useCallback(
    newOrderTable => {
      let orderOptionsCopy = { ...orderOptions }
      if (orderOptionsCopy.orderTableNumber !== newOrderTable) {
        dispatchOrderOptions(
          updateOrderOptions(
            orderOptionsCopy.orderType,
            newOrderTable,
            orderOptionsCopy.orderPickUpDate,
            orderOptionsCopy.orderPickUpTime,
            orderOptionsCopy.surchargeRate,
            orderOptionsCopy.lastUpdated
          )
        )
      }
    },
    [dispatchOrderOptions, orderOptions]
  )

  const updateOrderType = React.useCallback(
    (newOrderType, takeAwaySurcharge) => {
      let orderOptionsCopy = { ...orderOptions }
      let newSurchargeRate =
        newOrderType === "dineIn"
          ? storeSettings.surcharge
          : newOrderType === "takeAway"
          ? takeAwaySurcharge
          : orderOptionsCopy.surchargeRate
      if (
        orderOptionsCopy.orderType !== newOrderType ||
        JSON.stringify(orderOptionsCopy.surchargeRate) !==
          JSON.stringify(newSurchargeRate)
      ) {
        dispatchOrderOptions(
          updateOrderOptions(
            newOrderType,
            orderOptionsCopy.orderTableNumber,
            orderOptionsCopy.orderPickUpDate,
            orderOptionsCopy.orderPickUpTime,
            newSurchargeRate,
            newOrderType !== null ? Date.now() : orderOptionsCopy.lastUpdated
          )
        )
      }
    },
    [dispatchOrderOptions, orderOptions, storeSettings.surcharge]
  )

  const updateOrderTime = React.useCallback(
    (newOrderPickUpDate, newOrderPickUpTime) => {
      let orderOptionsCopy = { ...orderOptions }
      if (
        orderOptionsCopy.orderPickUpDate !== newOrderPickUpDate ||
        orderOptionsCopy.orderPickUpTime !== newOrderPickUpTime
      ) {
        dispatchOrderOptions(
          updateOrderOptions(
            orderOptionsCopy.orderType,
            orderOptionsCopy.orderTableNumber,
            newOrderPickUpDate,
            newOrderPickUpTime,
            orderOptionsCopy.surchargeRate,
            orderOptionsCopy.lastUpdated
          )
        )
      }
    },
    [dispatchOrderOptions, orderOptions]
  )

  const checkTime = React.useCallback(() => {
    let returnSurcharge = surchargeZero
    if (orderOptions.orderPickUpTime && orderOptions.orderPickUpDate) {
      let newIndex = storeSettings.orderWindows.findIndex(obj => {
        return obj.date.label === orderOptions.orderPickUpDate.label
      })
      if (newIndex >= 0) {
        let found = storeSettings.orderWindows[newIndex].slots.find(
          slot => slot === orderOptions.orderPickUpTime
        )

        if (found) {
          setSelectedWindow({
            dateLabel: orderOptions.orderPickUpDate.label,
            date: orderOptions.orderPickUpDate,
            time: orderOptions.orderPickUpTime,
            surcharge: storeSettings.orderWindows[newIndex].surcharge,
          })
          returnSurcharge = storeSettings.orderWindows[newIndex].surcharge
        } else {
          setSelectedWindow({
            dateLabel: null,
            date: null,
            time: null,
            surcharge: surchargeZero,
          })
          updateOrderTime(null, null)
        }
      } else {
        setSelectedWindow({
          dateLabel: null,
          date: null,
          time: null,
          surcharge: surchargeZero,
        })
        updateOrderTime(null, null)
      }
    } else {
      setSelectedWindow({
        dateLabel: null,
        date: null,
        time: null,
        surcharge: surchargeZero,
      })
      updateOrderTime(null, null)
    }
    return returnSurcharge
  }, [
    orderOptions.orderPickUpDate,
    orderOptions.orderPickUpTime,
    storeSettings.orderWindows,
    surchargeZero,
    updateOrderTime,
  ])

  const checkTable = React.useCallback(() => {
    if (orderOptions.orderTableNumber) {
      let found = storeSettings.tableList.find(
        table => table === orderOptions.orderTableNumber
      )
      if (found) {
        setSelectedTable(orderOptions.orderTableNumber)
      } else {
        setSelectedTable(null)
        updateOrderTable(null)
      }
    } else {
      setSelectedTable(null)
      updateOrderTable(null)
    }
  }, [orderOptions.orderTableNumber, updateOrderTable, storeSettings.tableList])

  const checkOrderOptions = React.useCallback(() => {
    checkTable()
    if (orderOptions.orderType === "takeAway") {
      if (storeSettings.isTakeAwayClosed) {
        if (!storeSettings.isDineInClosed && !storeSettings.isResOutOfHours) {
          setOrderType("dineIn")
          updateOrderType("dineIn")
          updateOrderTime(null, null)
        } else {
          setOrderType(null)
          updateOrderType(null)
        }
      } else {
        let takeAwaySurcharge = checkTime()
        setOrderType("takeAway")
        updateOrderType("takeAway", takeAwaySurcharge)
      }
    } else if (orderOptions.orderType === "dineIn") {
      if (storeSettings.isDineInClosed || storeSettings.isResOutOfHours) {
        if (!storeSettings.isTakeAwayClosed) {
          let takeAwaySurcharge = checkTime()
          setOrderType("takeAway")
          updateOrderType("takeAway", takeAwaySurcharge)
        } else {
          setOrderType(null)
          updateOrderType(null)
        }
      } else {
        setOrderType("dineIn")
        updateOrderTime(null, null)
        updateOrderType("dineIn")
      }
    } else {
      if (!storeSettings.isTakeAwayClosed) {
        let takeAwaySurcharge = checkTime()
        setOrderType("takeAway")
        updateOrderType("takeAway", takeAwaySurcharge)
      } else if (
        !storeSettings.isDineInClosed &&
        !storeSettings.isResOutOfHours
      ) {
        setOrderType("dineIn")
        updateOrderType("dineIn")
        updateOrderTime(null, null)
      } else {
        setOrderType(null)
        updateOrderType(null)
      }
    }
    setLoadingOptions(false)
  }, [
    orderOptions.orderType,
    storeSettings.isDineInClosed,
    storeSettings.isTakeAwayClosed,
    storeSettings.isResOutOfHours,
    updateOrderType,
    checkTime,
    checkTable,
    updateOrderTime,
  ])

  React.useEffect(() => {
    checkOrderOptions()
  }, [checkOrderOptions])

  React.useEffect(() => {
    if (orderOptions.orderType === "takeAway") {
      if (
        orderOptions.orderPickUpTime === null ||
        orderOptions.orderPickUpDate === null
      ) {
        setIsOrdOptError(true)
        setTextToShow({ chip: "Take Away", text: "Select a time", bold: "" })
        return
      } else {
        setIsOrdOptError(false)
        setTextToShow({
          chip: "Take Away",
          text: `${
            orderOptions.orderPickUpDate.dayLabel
              ? orderOptions.orderPickUpDate.dayLabel
              : orderOptions.orderPickUpDate.dayName
          }`,
          bold: `${toTime(orderOptions.orderPickUpTime)}`,
        })
        return
      }
    } else if (orderOptions.orderType === "dineIn") {
      if (orderOptions.orderTableNumber === null) {
        setIsOrdOptError(true)
        setTextToShow({ chip: "Dine-In", text: "Scan the QR code", bold: "" })
        return
      } else {
        setIsOrdOptError(false)
        setTextToShow({
          chip: "Dine-In",
          text: "Table ",
          bold: `${orderOptions.orderTableNumber}`,
        })
        return
      }
    } else {
      setIsOrdOptError(true)
      setTextToShow({ chip: "Select an order type", text: "", bold: "" })
      return
    }
  }, [orderOptions, setIsOrdOptError, setTextToShow])

  const saveOrdOptions = () => {
    if (orderType === "takeAway") {
      dispatchOrderOptions(
        updateOrderOptions(
          "takeAway",
          orderOptions.orderTableNumber,
          selectedWindow.date,
          selectedWindow.time,
          selectedWindow.surcharge,
          Date.now()
        )
      )
      handleClose()
    } else if (orderType === "dineIn") {
      setSelectedWindow({
        dateLabel: null,
        date: null,
        time: null,
        surcharge: surchargeZero,
      })
      dispatchOrderOptions(
        updateOrderOptions(
          "dineIn",
          selectedTable,
          null,
          null,
          storeSettings.surcharge,
          Date.now()
        )
      )
      handleClose()
    }
  }

  return (
    <>
      {loadingOptions ? (
        <Box
          classes={{
            root: clsx(classes.mainContainer, classes.mainContainerLoading),
          }}
        >
          <CircularProgress size="2rem" />
        </Box>
      ) : storeSettings.orderWindows.length < 1 &&
        storeSettings.isResOutOfHours ? (
        <></>
      ) : (
        <Box
          classes={{
            root: clsx(classes.mainContainer, {
              [classes.mainContainerError]: isOrdOptError,
            }),
          }}
          component={ButtonBase}
          disabled={
            orderOptions.orderType === "dineIn" &&
            orderOptions.orderTableNumber === null
          }
          onClick={() => {
            setOrderType(orderOptions.orderType)
            setOpen(true)
          }}
        >
          <Box classes={{ root: classes.tableBox }}>
            <Chip
              color="primary"
              label={<Typography variant="body1">{textToShow.chip}</Typography>}
              classes={{
                root: clsx(classes.orderTypeChip, {
                  [classes.orderTypeChipRed]: isOrdOptError,
                }),
              }}
            ></Chip>

            <Box classes={{ root: classes.orderChoiceChip }}>
              <Box
                classes={{
                  root: clsx({
                    [classes.tableBox]: orderOptions.orderType !== "takeAway",
                  }),
                }}
              >
                <Typography
                  classes={{
                    root: clsx({
                      [classes.errorText]: isOrdOptError,
                      [classes.tableNormTextTime]:
                        orderOptions.orderType === "takeAway",
                    }),
                  }}
                  variant="body1"
                >
                  {textToShow.text}
                </Typography>
                <Typography
                  variant="body1"
                  classes={{
                    root: clsx(classes.tableBold, {
                      [classes.errorText]: isOrdOptError,
                      [classes.tableBoldTime]:
                        orderOptions.orderType === "takeAway",
                    }),
                  }}
                >
                  {textToShow.bold}
                </Typography>
              </Box>
            </Box>
          </Box>
          <IconButton
            classes={{ root: classes.editBut }}
            component={Box}
            color="primary"
          >
            <EditOutlinedIcon />
          </IconButton>
        </Box>
      )}
      <Dialog
        open={open}
        onClose={handleClose}
        scroll={"paper"}
        maxWidth={"xs"}
        fullWidth
        aria-labelledby="order-details-title"
        aria-describedby="order-details-description"
        classes={{
          paperFullWidth: classes.dialogPaperFullWidth,
          paper: classes.dialogPaper,
          paperScrollPaper: classes.dialogPaperScrollPaper,
        }}
      >
        <CartDialogContent
          storeSettings={storeSettings}
          selectedWindow={selectedWindow}
          setSelectedWindow={setSelectedWindow}
          selectedTable={selectedTable}
          setSelectedTable={setSelectedTable}
          orderType={orderType}
          setOrderType={setOrderType}
          orderOptions={orderOptions}
        />

        <DialogActions classes={{ root: classes.dialogActionsArea }}>
          <IconButton
            onClick={handleClose}
            aria-label="close"
            color="primary"
            classes={{
              root: classes.xBut,
            }}
          >
            <CloseIcon></CloseIcon>
          </IconButton>
          <Button
            onClick={saveOrdOptions}
            variant="contained"
            size="large"
            color="primary"
            disableElevation
            fullWidth
            disabled={
              orderType === "takeAway"
                ? !selectedWindow.dateLabel &&
                  !selectedWindow.date &&
                  !selectedWindow.time
                : orderType === "dineIn"
                ? !selectedTable
                : true
            }
            classes={{ root: classes.but }}
          >
            <Typography variant="body2" classes={{ root: classes.butText }}>
              {"Confirm"}
            </Typography>
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default CartOrderType
