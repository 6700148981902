import * as React from "react"
import clsx from "clsx"
import { Link } from "gatsby"
import { navigate } from "gatsby"
import { useForm } from "react-hook-form"
import axios from "axios"
import Card from "@material-ui/core/Card"
import CardContent from "@material-ui/core/CardContent"
import Typography from "@material-ui/core/Typography"
import CardActions from "@material-ui/core/CardActions"
import CircularProgress from "@material-ui/core/CircularProgress"
import Grid from "@material-ui/core/Grid"
import Box from "@material-ui/core/Box"
import Button from "@material-ui/core/Button"
import Backdrop from "@material-ui/core/Backdrop"
import Chip from "@material-ui/core/Chip"
import Container from "@material-ui/core/Container"
import ShoppingBagOutlinedIcon from "@mui/icons-material/ShoppingBagOutlined"
import CartFooter from "../../components/ui/stickyFooter"
import CartItem from "./CartItem"
import CheckoutPanel from "./CheckoutPanel"
import CartServiceItem from "./CartServiceItem"
import priceFormat from "../../helperFunc/priceFormat"
import isClosed from "../../helperFunc/isClosed"
import CartAlert from "./CartAlert"
import CartOrderType from "./CartOrderType"
import CartOrderNotesField from "./CartOrderNotesField"
import handleError from "../../helperFunc/handleError"

import { StoreSettingsContext } from "../../contexts"

import {
  updateInCart,
  setUser,
  updateOrderOptions,
  setStoreSettings,
  setOrderNotes,
} from "../../contexts/actions"

import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles(theme => ({
  mainIcon: {
    verticalAlign: "sub",
    fontSize: "2rem !important",
    marginRight: "0.5rem",
  },

  noItemsIcon: {
    fontSize: "4rem !important",
  },

  inMainContainer: {
    paddingLeft: 0,
    paddingRight: 0,
    marginTop: "4.0rem",
    marginBottom: "5rem",
    maxWidth: "100%",
    [theme.breakpoints.up("md")]: {
      maxWidth: "40rem",
      marginTop: "6.0rem",
      marginBottom: "2rem",
    },
  },
  boxGrids: {
    padding: 0,
  },
  mainCard: {
    borderRadius: 0,
    [theme.breakpoints.up("md")]: {
      borderRadius: "1rem",
    },
  },
  titleArea: {
    backgroundColor: theme.palette.common.darkGrey,
    paddingTop: "0.5rem",
    paddingBottom: "0.5rem !important",
  },
  titleText: {
    textTransform: "uppercase",
    fontSize: "1.5rem",
    paddingLeft: "0.5rem",
    paddingRight: "0.5rem",
    color: theme.palette.common.white,
  },

  contentArea: {
    paddingLeft: "1.5rem",
    paddingRight: "1.5rem",
    backgroundColor: theme.palette.common.white,
  },

  bottomArea: {
    backgroundColor: theme.palette.common.offWhite,
    height: "0px",
    padding: "0px",
  },

  circularProgress: {
    verticalAlign: "middle",
  },

  circularProgressCont: {
    minWidth: "4rem",
  },

  innerContainer: {
    width: "100%",
  },
  mainContainer: {
    minHeight: "calc(100vh  - 100px)",

    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  },

  butChip: {
    backgroundColor: theme.palette.common.white,
    marginLeft: "1rem",
    cursor: "pointer",
  },

  price: { fontSize: "0.9rem", lineHeight: "1.1rem" },

  butText: {
    fontSize: "1.2rem",
  },

  but: {
    height: "3rem",
    pointerEvents: "auto",
    paddingRight: "0.5rem",
    borderRadius: "10rem",
  },
  butNoPadding: {
    paddingRight: "1.5rem !important",
    paddingLeft: "1.5rem !important",
  },
  butDisabled: {
    height: "3rem !important",
    backgroundColor: `${theme.palette.common.seventyWhite} !important`,
    color: `${theme.palette.common.white} !important`,
  },

  butBacktoMenu: {
    height: "3rem",
    pointerEvents: "auto",
    borderRadius: "10rem",
  },

  noItemsCont: {
    paddingTop: "20vh",
    paddingBottom: "20vh",
    textAlign: "center",
  },

  outBox: {
    width: "100%",
    minHeight: "calc(100vh  - 66px)",
    position: "relative",
    overflowY: "scroll",
  },

  inBox: {
    width: "80%",
    textAlign: "center",
    position: "absolute",
    left: "50%",
    top: "50%",
    msTransform: "translate(-50%, -50%)",
    transform: "translate(-50%, -50%)",
  },

  "@global": {
    body: {
      [theme.breakpoints.down("sm")]: {
        backgroundColor: `${theme.palette.common.white} !important`,
      },
    },
  },
  backdrop: {
    zIndex: `${theme.zIndex.drawer + 1}`,
  },
}))

const CartPanel = ({
  user,
  dispatchUser,
  defaultUser,
  cart,
  dispatchCart,
  dispatchFeedback,
  feedback,
  orderOptions,
  dispatchOrderOptions,
  location,
}) => {
  const [order, setOrder] = React.useState(false)
  const [isCheckout, setIsCheckout] = React.useState(false)
  const [showCart, setShowCart] = React.useState(false)
  const [loading, setLoading] = React.useState(false)
  const [cartAlert, setCartAlert] = React.useState(false)
  const [isContButDisable, setIsContButDisable] = React.useState(true)
  const [isOrdOptError, setIsOrdOptError] = React.useState(true)
  const [open, setOpen] = React.useState(false)
  const [textToShow, setTextToShow] = React.useState({
    chip: "",
    text: "",
    bold: "",
  })

  const hasTested = React.useRef(false)

  const { storeSettings, dispatchStoreSettings } =
    React.useContext(StoreSettingsContext)

  const { control, formState, watch } = useForm({
    mode: "onChange",
  })
  let { orderNotes } = watch()
  const { isValid } = formState

  const classes = useStyles()

  const setShowCartTrue = () => {
    setShowCart(true)
  }

  const setCartTrue = () => {
    setIsCheckout(false)
  }

  const handleCheckoutTest = () => {
    setLoading(true)
    setCartAlert(false)

    if (cart.length > 0) {
      if (user.jwt) {
        let cartToSend = cart.map(item => {
          return { ...item, product: null }
        })
        if (orderOptions.totalOrderPrice) {
          axios
            .post(
              process.env.GATSBY_STRAPI_URL + "/api/orders/place",
              {
                items: cartToSend,
                subtotal: orderOptions.totalOrderPrice,
                serviceFee: orderOptions.serviceFee,
                surchargeFee: orderOptions.surchargeFee,
                total: parseFloat(
                  (
                    orderOptions.totalOrderPrice +
                    orderOptions.serviceFee +
                    orderOptions.surchargeFee.phSurchargeFee +
                    orderOptions.surchargeFee.sunSurchargeFee +
                    orderOptions.surchargeFee.satSurchargeFee
                  ).toFixed(2)
                ),
                tax: orderOptions.tax,
                orderPickUpDate: orderOptions.orderPickUpDate,
                orderPickUpTime: orderOptions.orderPickUpTime,
                orderTableNumber: orderOptions.orderTableNumber,
                orderType: orderOptions.orderType,
                orderNotes: orderOptions.orderNotes,
              },
              {
                headers: {
                  Authorization: `Bearer ${user.jwt}`,
                },
              }
            )
            .then(response => {
              setLoading(false)
              setIsCheckout(true)
              setOrder(response.data.order)
            })
            .catch(error => {
              setLoading(false)
              setIsCheckout(false)
              setShowCartTrue()
              switch (error.response.status) {
                case 400:
                  setCartAlert(true)
                  break
                case 410:
                  handleError(error, dispatchFeedback)
                  dispatchOrderOptions(
                    updateOrderOptions(
                      storeSettings.isTakeAwayClosed ? null : "takeAway",
                      null,
                      null,
                      null,
                      {
                        phSurchargeRate: 0,
                        sunSurchargeRate: 0,
                        satSurchargeRate: 0,
                      },
                      Date.now()
                    )
                  )
                  let timeDiff =
                    Date.now() - error.response.data.storeSetting.dateNow
                  let {
                    isResOutOfHours,
                    minutesToClose,
                    surcharge,
                    orderWindows,
                    tableList,
                  } = isClosed(error.response.data.storeSetting, timeDiff)
                  let dataToSet = {
                    ...error.response.data.storeSetting,
                    isResOutOfHours: isResOutOfHours,
                    minutesToClose: minutesToClose,
                    surcharge: surcharge,
                    timeDiff: timeDiff,
                    orderWindows: orderWindows,
                    tableList: tableList,
                  }
                  dispatchStoreSettings(setStoreSettings(dataToSet))
                  console.log(error.response.data)
                  break
                case 409:
                  error.response.data.unavailable.map(item => {
                    dispatchCart(updateInCart(false, item.itemId))
                    return true
                  })
                  break
                default:
                  handleError(error, dispatchFeedback)
              }
            })
        } else {
          setLoading(false)
          setIsCheckout(false)
          setShowCartTrue()
        }
      } else {
        navigate("/account/", {
          state: { isCheckoutStep: true },
          replace: true,
        })
      }
    } else {
      setLoading(false)
      setIsCheckout(false)
      setShowCartTrue()
    }
  }

  React.useEffect(() => {
    if (location.state) {
      if (location.state.isCheckoutStep) {
        window.history.replaceState(null, null, window.location.pathname)
        if (!hasTested.current) {
          hasTested.current = true
          handleCheckoutTest()
        }
      } else {
        setCartTrue()
        setShowCartTrue()
      }
    } else {
      setCartTrue()
      setShowCartTrue()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.state])

  React.useEffect(() => {
    if (orderNotes !== undefined) {
      dispatchOrderOptions(setOrderNotes(orderNotes, Date.now()))
    }
  }, [dispatchOrderOptions, orderNotes])

  React.useEffect(() => {
    if (user) {
      if (!user.onboarding) {
        dispatchUser(setUser({ ...user, onboarding: true }))
      }
    }
  }, [dispatchUser, user])

  return isCheckout ? (
    <CheckoutPanel
      user={user}
      dispatchUser={dispatchUser}
      defaultUser={defaultUser}
      cart={cart}
      dispatchCart={dispatchCart}
      dispatchFeedback={dispatchFeedback}
      feedback={feedback}
      storeSettings={storeSettings}
      dispatchOrderOptions={dispatchOrderOptions}
      order={order}
    ></CheckoutPanel>
  ) : !showCart ? (
    <Box classes={{ root: classes.outBox }}>
      <Box classes={{ root: classes.inBox }}>
        <CircularProgress color="primary" />
      </Box>
    </Box>
  ) : (
    <>
      <Grid
        container
        justifyContent="center"
        alignItems="flex-start"
        classes={{ root: classes.mainContainer }}
      >
        <Grid item classes={{ root: classes.innerContainer }}>
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
          >
            <Container classes={{ root: classes.inMainContainer }}>
              <Grid item classes={{ root: classes.boxGrids }}>
                <Card classes={{ root: classes.mainCard }} elevation={0}>
                  <CardContent classes={{ root: classes.titleArea }}>
                    <Typography
                      variant="h6"
                      classes={{ root: classes.titleText }}
                    >
                      {"Order Details"}
                    </Typography>
                  </CardContent>
                  <CardActions
                    disableSpacing
                    classes={{ root: classes.bottomArea }}
                  ></CardActions>
                  <CardContent classes={{ root: classes.contentArea }}>
                    <CartAlert
                      cart={cart}
                      dispatchCart={dispatchCart}
                      cartAlert={cartAlert}
                      setIsContButDisable={setIsContButDisable}
                      setCartAlert={setCartAlert}
                      storeSettings={storeSettings}
                    ></CartAlert>
                    <CartOrderType
                      orderOptions={orderOptions}
                      dispatchOrderOptions={dispatchOrderOptions}
                      isOrdOptError={isOrdOptError}
                      setIsOrdOptError={setIsOrdOptError}
                      storeSettings={storeSettings}
                      textToShow={textToShow}
                      setTextToShow={setTextToShow}
                      open={open}
                      setOpen={setOpen}
                    ></CartOrderType>
                    {cart.length > 0 ? (
                      <>
                        {cart.map((item, index) => (
                          <CartItem
                            item={item}
                            key={index}
                            last={index + 1 === cart.length}
                          ></CartItem>
                        ))}
                        <CartServiceItem
                          surchargeFee={orderOptions.surchargeFee}
                          fee={orderOptions.serviceFee}
                          subTotal={orderOptions.totalOrderPrice}
                        />
                        <CartOrderNotesField
                          control={control}
                          orderOptions={orderOptions}
                        ></CartOrderNotesField>
                      </>
                    ) : (
                      <Box classes={{ root: classes.noItemsCont }}>
                        <ShoppingBagOutlinedIcon
                          classes={{ root: classes.noItemsIcon }}
                        />
                        <Typography variant="body1">
                          {"No items have been added yet!"}
                        </Typography>
                      </Box>
                    )}
                  </CardContent>
                </Card>
              </Grid>
            </Container>
          </Grid>
        </Grid>
      </Grid>
      <CartFooter>
        {cart.length > 0 &&
        !(
          storeSettings.orderWindows.length < 1 && storeSettings.isResOutOfHours
        ) ? (
          <Button
            variant="contained"
            size="large"
            color="primary"
            disabled={
              loading ||
              isContButDisable ||
              !isValid ||
              (orderOptions.orderType === "dineIn" &&
                orderOptions.orderTableNumber === null)
            }
            classes={{
              root: clsx(classes.but, {
                [classes.butNoPadding]: isOrdOptError,
              }),
              disabled: classes.butDisabled,
            }}
            onClick={
              isOrdOptError && !isContButDisable && !loading && isValid
                ? e => {
                    setOpen(true)
                  }
                : !isContButDisable && !loading && isValid
                ? handleCheckoutTest
                : undefined
            }
            disableElevation
          >
            {loading ? (
              <Box classes={{ root: classes.circularProgressCont }}>
                <CircularProgress
                  size="1.5rem"
                  classes={{ root: classes.circularProgress }}
                />
              </Box>
            ) : isOrdOptError ? (
              <Typography variant="body1" classes={{ root: classes.butText }}>
                {`${textToShow.text ? textToShow.text : textToShow.chip}`}
              </Typography>
            ) : (
              <Typography variant="body1" classes={{ root: classes.butText }}>
                {"Continue"}
              </Typography>
            )}
            {isOrdOptError ? (
              <></>
            ) : (
              <Chip
                label={
                  <Typography variant="body1" classes={{ root: classes.price }}>
                    {priceFormat(
                      parseFloat(
                        (
                          orderOptions.totalOrderPrice +
                          orderOptions.serviceFee +
                          orderOptions.surchargeFee.phSurchargeFee +
                          orderOptions.surchargeFee.sunSurchargeFee +
                          orderOptions.surchargeFee.satSurchargeFee
                        ).toFixed(2)
                      )
                    )}
                  </Typography>
                }
                classes={{ root: classes.butChip }}
              ></Chip>
            )}
          </Button>
        ) : (
          <Button
            component={Link}
            to={"/menu"}
            variant="contained"
            size="large"
            color="primary"
            classes={{ root: classes.butBacktoMenu }}
            disableElevation
          >
            <Typography variant="body1" classes={{ root: classes.butText }}>
              {"Return to menu"}
            </Typography>
          </Button>
        )}
      </CartFooter>
      <Backdrop open={loading} classes={{ root: classes.backdrop }}>
        <CircularProgress color="secondary" />
      </Backdrop>
    </>
  )
}

export default CartPanel
