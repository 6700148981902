import * as React from "react"
import Paper from "@material-ui/core/Paper"
import Container from "@material-ui/core/Container"

//import Typography from "@material-ui/core/Typography"

import { makeStyles } from "@material-ui/core/styles"
const useStyles = makeStyles(theme => ({
  mainContainer: {
    paddingLeft: "1.5rem",
    paddingRight: "1.5rem",
    paddingTop: "1rem",
    paddingBottom: "1.5rem",
    width: "100%",
    bottom: 0,
    textAlign: "center",

    [theme.breakpoints.down("sm")]: {
      paddingTop: "1rem",
      paddingBottom: "1.5rem",
    },
  },
  mainBox: {
    boxShadow: "0px -3px 2px -2px rgba(0, 0, 0, 0.15)",
    pointerEvents: "none",
    bottom: 0,
    left: 0,
    right: 0,
    borderRadius: 0,
    backgroundColor: "rgba(255,255,255,0)",
    backdropFilter: "blur(3px)",
    zIndex: "10",
    backgroundImage: `linear-gradient(to bottom, rgba(255,255,255,0.5), rgba(250,250,250,1))`,
    [theme.breakpoints.down("sm")]: {
      left: 0,
      borderRadius: 0,
      position: "fixed",
    },
  },
  textCol: {
    color: theme.palette.common.seventyWhite,
  },
}))

function StickyFooter({ children }) {
  const classes = useStyles()

  return (
    <Paper classes={{ root: classes.mainBox }} elevation={0}>
      <Container classes={{ root: classes.mainContainer }}>
        {children}
      </Container>
    </Paper>
  )
}

export default StickyFooter
