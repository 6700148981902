import * as React from "react"
import clsx from "clsx"
import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"

import Button from "@material-ui/core/Button"
import Dialog from "@material-ui/core/Dialog"
import Box from "@material-ui/core/Box"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import DialogContentText from "@material-ui/core/DialogContentText"
import DialogTitle from "@material-ui/core/DialogTitle"

import IconButton from "@material-ui/core/IconButton"
import AddIcon from "@mui/icons-material/Add"
import RemoveIcon from "@mui/icons-material/Remove"

import { CartContext } from "../../contexts"
import { incInCart, removeFromCart } from "../../contexts/actions"

import { makeStyles } from "@material-ui/core/styles"
const useStyles = makeStyles(theme => ({
  butSolidBlack: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    padding: "0.25rem",
    "&:hover": {
      backgroundColor: theme.palette.primary.light,
    },
  },

  butSolidGrey: {
    backgroundColor: theme.palette.common.grey,
    color: theme.palette.common.white,
    padding: "0.25rem",
    "&:hover": {
      backgroundColor: theme.palette.primary.light,
    },
  },

  butSolidDisabled: {
    backgroundColor: `${theme.palette.common.almostWhite} !important`,
  },

  textFieldNumberContainer: {
    maxWidth: "2rem",
  },
  alertTitCont: {
    paddingBottom: 0,
  },
  iconSize: {
    fontSize: "1.2rem !important",
  },
  alertTitle: { fontSize: "1.5rem !important", marginTop: "0.5rem" },
  butGridCont: {
    paddingLeft: "0.5rem",
    paddingRight: "0.5rem",
    paddingBottom: "1rem",
  },

  butGrid: {
    paddingLeft: "0.5rem",
    paddingRight: "0.5rem",
  },
  but: {
    paddingTop: "1rem",
    paddingBottom: "1rem",
  },

  butRemove: {
    paddingTop: "1rem",
    paddingBottom: "1rem",
    backgroundColor: `${theme.palette.secondary.light} !important`,
    color: `${theme.palette.common.white} !important`,
    "&:hover": {
      backgroundColor: `${theme.palette.secondary.dark} !important`,
    },
  },
  textFieldNumber: {
    color: theme.palette.primary.main,
    fontSize: "1rem",
    verticalAlign: "middle",
    backgroundColor: "transparent",
    borderRadius: "3rem",
    textAlignLast: "center",
    width: "2rem",
    borderWidth: 0,
    marginTop: 0,
    marginBottom: 0,
    paddingBottom: 0,
    "&:focus-visible": {
      outline: 0,
    },
  },
}))

function CartCounter({ qty, item, showQuantity }) {
  const [open, setOpen] = React.useState(false)
  const [remove, setRemove] = React.useState(false)

  const { dispatchCart } = React.useContext(CartContext)
  const handleIncrement = () => {
    if (qty < 999) {
      dispatchCart(incInCart(1, item.itemId))
    }
  }

  const handleDecrement = () => {
    if (qty < 2) {
      setOpen(true)
    } else {
      dispatchCart(removeFromCart(1, item.itemId))
    }
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleRemove = () => {
    setRemove(true)
    setOpen(false)
  }

  React.useEffect(() => {
    if (remove) {
      dispatchCart(removeFromCart(1, item.itemId))
      setRemove(false)
    }
  }, [remove, dispatchCart, item.itemId])

  const classes = useStyles()

  return (
    <Grid container direction="row" alignItems="center" wrap="nowrap">
      <Grid item>
        <IconButton
          onClick={handleDecrement}
          classes={{ root: classes.butSolidBlack }}
        >
          <RemoveIcon classes={{ root: classes.iconSize }} />
        </IconButton>
      </Grid>
      <Grid xs item classes={{ root: classes.textFieldNumberContainer }}>
        <input className={classes.textFieldNumber} value={qty} readOnly />
      </Grid>

      <Grid item>
        <IconButton
          onClick={handleIncrement}
          disabled={!showQuantity}
          classes={{
            root: clsx({
              [classes.butSolidGrey]: qty < 1,
              [classes.butSolidBlack]: qty >= 1,
            }),
            disabled: classes.butSolidDisabled,
          }}
        >
          <AddIcon classes={{ root: classes.iconSize }} />
        </IconButton>
      </Grid>
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth={"xs"}
        fullWidth
        aria-labelledby="alert-remove-item-title"
        aria-describedby="alert-remove-item-description"
      >
        <DialogTitle
          id="alert-remove-item-title"
          classes={{ root: classes.alertTitCont }}
        >
          <Box classes={{ root: classes.alertTitle }}>{"Remove item?"}</Box>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-remove-item-description">
            {"Are you sure you want to remove this item?"}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Grid
            container
            direction="row"
            alignItems="center"
            classes={{ root: classes.butGridCont }}
          >
            <Grid item xs={6} classes={{ root: classes.butGrid }}>
              <Button
                onClick={handleClose}
                variant="outlined"
                size="large"
                color="primary"
                disableElevation
                fullWidth
                classes={{ root: classes.but }}
              >
                <Typography variant="body1">{"Cancel"}</Typography>
              </Button>
            </Grid>
            <Grid item xs={6} classes={{ root: classes.butGrid }}>
              <Button
                onClick={handleRemove}
                variant="contained"
                size="large"
                disableElevation
                fullWidth
                classes={{ root: classes.butRemove }}
              >
                <Typography variant="body1">{"Remove"}</Typography>
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </Grid>
  )
}

export default CartCounter
