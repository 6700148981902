import * as React from "react"
import clsx from "clsx"
import { navigate } from "gatsby"
import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"
import Chip from "@material-ui/core/Chip"
import Divider from "@material-ui/core/Divider"
import Avatar from "@material-ui/core/Avatar"
import Box from "@material-ui/core/Box"

import priceFormat from "../../helperFunc/priceFormat"
import CartCounter from "./CartCounter"
import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles(theme => ({
  textContainer: {
    paddingRight: "1.5rem",
    cursor: "pointer",
  },

  AvatarLabelBox: {
    display: "flex",
    alignItems: "center",
  },

  unavailable: {
    backgroundColor: "rgb(253, 237, 237)",
    color: "#ef5350",
  },

  avatar: {
    width: "3rem",
    height: "3rem",
    marginRight: "0.5rem",
  },
  descriptionText: {
    color: theme.palette.common.grey,
    fontSize: "0.9rem",
    lineHeight: "1.1rem",
    display: "inline-block",
    marginRight: "0.2rem",
    overflowWrap: "anywhere",
  },

  descriptionTextBold: {
    lineHeight: "1.1rem",
    display: "inline-block",
    fontWeight: 600,
  },

  descriptionTextBox: {
    lineHeight: "0.5rem",
    marginTop: "0.1rem",
  },

  itemHeading: {
    fontSize: "1.1rem",
    lineHeight: "1.3rem",
  },
  priceChip: {
    width: "100%",
    marginBottom: "0.3rem",
    backgroundColor: theme.palette.common.almostWhite,
  },
  price: { fontSize: "0.9rem", lineHeight: "1.1rem" },

  divider: {
    marginTop: "1rem",
    marginBottom: "1rem",
  },
}))

const CartItem = ({ item, last }) => {
  const classes = useStyles()

  const handleReturnToProduct = e => {
    navigate(
      `/${item.product.category.slug.toLowerCase()}/${encodeURIComponent(
        item.product.name.toLowerCase().replace(/\s/g, "")
      )}`,
      {
        state: { itemId: item.itemId },
      }
    )
  }

  return (
    <>
      <Grid item>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          wrap="nowrap"
          classes={{ root: classes.mainContainer }}
        >
          <Grid
            item
            classes={{ root: classes.textContainer }}
            onClick={handleReturnToProduct}
          >
            <Box classes={{ root: classes.AvatarLabelBox }}>
              <Avatar
                classes={{ root: classes.avatar }}
                src={
                  item.product.images[0]
                    ? `${
                        item.product.images[1]
                          ? item.product.images[1].url
                          : item.product.images[0].url
                      }`
                    : " "
                }
                alt={item.name}
              />

              <Typography variant="h6" classes={{ root: classes.itemHeading }}>
                {item.name}
              </Typography>
            </Box>
            {item.isAvailable ? (
              <></>
            ) : (
              <Chip
                label="Unavailable"
                size="small"
                classes={{ root: classes.unavailable }}
              />
            )}

            {Object.keys(item.orderSelectedItems).map((objkey, index) =>
              item.orderSelectedItems[objkey].fieldPrintLabel ? (
                <Box key={index} classes={{ root: classes.descriptionTextBox }}>
                  <Typography
                    variant="body1"
                    classes={{
                      root: clsx(
                        classes.descriptionText,
                        classes.descriptionTextBold
                      ),
                    }}
                  >
                    {`${item.orderSelectedItems[objkey].fieldPrintLabel}`}
                  </Typography>

                  {item.orderSelectedItems[objkey].selectedItems[0]
                    .optionTextVal ? (
                    <Typography
                      variant="body1"
                      classes={{ root: classes.descriptionText }}
                    >
                      {`${item.orderSelectedItems[objkey].selectedItems[0].optionTextVal}`}
                    </Typography>
                  ) : (
                    item.orderSelectedItems[objkey].selectedItems.map(
                      (inSelectedItem, index) => {
                        return (
                          <Typography
                            variant="body1"
                            classes={{ root: classes.descriptionText }}
                            key={index}
                          >
                            {`${
                              item.orderSelectedItems[objkey].fieldType ===
                                "quantityBox" || inSelectedItem.quantity > 1
                                ? `${inSelectedItem.quantity}x `
                                : ""
                            }${inSelectedItem.optionPrintLabel}${
                              index + 1 <
                              item.orderSelectedItems[objkey].selectedItems
                                .length
                                ? `, `
                                : ""
                            }`}
                          </Typography>
                        )
                      }
                    )
                  )}
                </Box>
              ) : null
            )}
          </Grid>
          <Grid item>
            <Chip
              label={
                <Typography variant="body1" classes={{ root: classes.price }}>
                  {priceFormat(
                    parseFloat((item.qty * item.totalItemPrice).toFixed(2))
                  )}
                </Typography>
              }
              classes={{ root: classes.priceChip }}
            ></Chip>
            <CartCounter
              qty={item.qty}
              item={item}
              showQuantity={item.product.showQuantity}
            ></CartCounter>
          </Grid>
        </Grid>
      </Grid>
      {!last && <Divider classes={{ root: classes.divider }} />}
    </>
  )
}

export default CartItem
