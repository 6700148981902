import * as React from "react"
import ToggleButton from "@material-ui/lab/ToggleButton"
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup"
import Typography from "@material-ui/core/Typography"
import Grid from "@material-ui/core/Grid"

import amex from "../../images/cardIcons/amex.svg"
import diners_club from "../../images/cardIcons/diners_club.svg"
import discover from "../../images/cardIcons/discover.svg"
import elo from "../../images/cardIcons/elo.svg"
import jcb from "../../images/cardIcons/jcb.svg"
import mastercard from "../../images/cardIcons/mastercard.svg"
import unionpay from "../../images/cardIcons/unionpay.svg"
import visa from "../../images/cardIcons/visa.svg"
import otherCard from "../../images/cardIcons/otherCard.svg"

import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked"
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked"

import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles(theme => ({
  subTitle: {
    fontSize: "1.2rem",
    marginBottom: "0.75rem",
  },

  togButs: {
    width: "100%",
    backgroundColor: `#00000000 !important`,
    marginBottom: "1.5rem",
  },
  togButText: {
    textTransform: "none !important",
    fontWeight: "500 !important",
    marginRight: "0.5rem",
  },

  togButTextYear: {
    textTransform: "none !important",
    fontWeight: "400 !important",
  },
  standardBut: {
    display: "flex",

    fontSize: "1rem !important",
    border: `2px solid #00000000 !important`,
    borderRadius: "0.5rem !important",
    lineHeight: "0.5rem !important",
    height: "3.5rem !important",
    "&:hover": {
      backgroundColor: `#00000000 !important`,
    },
  },
  selectedBut: {
    backgroundColor: `#00000000 !important`,
    color: `${theme.palette.primary.main} !important`,
    border: `2px solid ${theme.palette.primary.main} !important`,
    borderRadius: "0.5rem !important",
  },

  iconGrid: {
    paddingRight: "0.75rem",
  },
}))

const cardIcons = {
  amex: { icon: amex, name: "AMEX" },
  diners_club: { icon: diners_club, name: "DINERS CLUB" },
  discover: { icon: discover, name: "DISCOVER" },
  elo: { icon: elo, name: "ELO" },
  jcb: { icon: jcb, name: "JCB" },
  mastercard: { icon: mastercard, name: "MASTERCARD" },
  unionpay: { icon: unionpay, name: "UNIONPAY" },
  visa: { icon: visa, name: "VISA" },
  other: { icon: otherCard, name: "CARD" },
}

const SavedCards = ({ existingCards, selectedCardId, setSelectedCardId }) => {
  const classes = useStyles()

  React.useEffect(() => {
    if (existingCards.length > 0) {
      setSelectedCardId(existingCards[0].id)
    }
  }, [existingCards, setSelectedCardId])

  const handleChange = (event, cardId) => {
    if (cardId !== null) {
      setSelectedCardId(cardId)
    }
  }

  return (
    <>
      <Typography variant="h6" classes={{ root: classes.subTitle }}>
        {"Choose your payment method"}
      </Typography>
      <ToggleButtonGroup
        orientation="vertical"
        value={selectedCardId}
        exclusive
        onChange={handleChange}
        classes={{ root: classes.togButs }}
      >
        {existingCards.map((card, index) => {
          return (
            <ToggleButton
              value={card.id}
              aria-label={card.card.last4}
              key={index}
              classes={{
                root: classes.standardBut,
                selected: classes.selectedBut,
                disabled: classes.togButDisabled,
              }}
            >
              <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <Grid item>
                  <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                  >
                    <Grid item classes={{ root: classes.iconGrid }}>
                      <img
                        src={
                          cardIcons[card.card.brand]
                            ? cardIcons[card.card.brand].icon
                            : cardIcons.other.icon
                        }
                        alt={
                          cardIcons[card.card.brand]
                            ? cardIcons[card.card.brand].name
                            : cardIcons.other.name
                        }
                      ></img>
                    </Grid>
                    <Grid item>
                      <Typography
                        variant="body1"
                        classes={{ root: classes.togButText }}
                      >{`●●●● ${card.card.last4}`}</Typography>
                    </Grid>
                    <Grid item>
                      <Typography
                        variant="body1"
                        classes={{ root: classes.togButTextYear }}
                      >{`${card.card.exp_month}/${card.card.exp_year}`}</Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item>
                  {card.id === selectedCardId ? (
                    <RadioButtonCheckedIcon />
                  ) : (
                    <RadioButtonUncheckedIcon />
                  )}
                </Grid>
              </Grid>
            </ToggleButton>
          )
        })}

        <ToggleButton
          value={"newCard"}
          aria-label={"newCard"}
          classes={{
            root: classes.standardBut,
            selected: classes.selectedBut,
            disabled: classes.togButDisabled,
          }}
        >
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid item>
              <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
              >
                <Grid item classes={{ root: classes.iconGrid }}>
                  <img
                    src={cardIcons.other.icon}
                    alt={cardIcons.other.name}
                  ></img>
                </Grid>
                <Grid item>
                  <Typography
                    variant="body1"
                    classes={{ root: classes.togButText }}
                  >{`New payment method`}</Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              {"newCard" === selectedCardId ? (
                <RadioButtonCheckedIcon />
              ) : (
                <RadioButtonUncheckedIcon />
              )}
            </Grid>
          </Grid>
        </ToggleButton>
      </ToggleButtonGroup>
    </>
  )
}

export default SavedCards
