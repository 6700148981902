import * as React from "react"
import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"
import Divider from "@material-ui/core/Divider"

import priceFormat from "../../helperFunc/priceFormat"
import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles(theme => ({
  textContainer: {
    paddingRight: "1.5rem",
  },

  itemHeading: {
    fontSize: "0.9rem",
    lineHeight: "1.1rem",
  },

  itemHeadingBold: {
    fontSize: "0.9rem",
    lineHeight: "1.1rem",
    fontWeight: 600,
  },
  price: { fontSize: "0.9rem", lineHeight: "1.1rem" },
  priceBold: { fontSize: "0.9rem", lineHeight: "1.1rem", fontWeight: 600 },
  divider: {
    marginTop: "1rem",
    marginBottom: "1rem",
  },
}))

const CartServiceItem = ({ surchargeFee, fee, subTotal }) => {
  const classes = useStyles()

  return (
    <>
      <Divider classes={{ root: classes.divider }} />
      <Grid item>
        {fee > 0 ||
        surchargeFee.phSurchargeFee > 0 ||
        surchargeFee.sunSurchargeFee > 0 ||
        surchargeFee.satSurchargeFee > 0 ? (
          <>
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              wrap="nowrap"
              classes={{ root: classes.mainContainer }}
            >
              <Grid item classes={{ root: classes.textContainer }}>
                <Typography
                  variant="body1"
                  classes={{ root: classes.itemHeading }}
                >
                  {"Subtotal"}
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="body1" classes={{ root: classes.price }}>
                  {priceFormat(subTotal)}
                </Typography>
              </Grid>
            </Grid>
            {fee > 0 ? (
              <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                wrap="nowrap"
                classes={{ root: classes.mainContainer }}
              >
                <Grid item classes={{ root: classes.textContainer }}>
                  <Typography
                    variant="body1"
                    classes={{ root: classes.itemHeading }}
                  >
                    {"Service Fee"}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant="body1" classes={{ root: classes.price }}>
                    {priceFormat(fee)}
                  </Typography>
                </Grid>
              </Grid>
            ) : (
              <></>
            )}
            {surchargeFee.phSurchargeFee > 0 ? (
              <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                wrap="nowrap"
                classes={{ root: classes.mainContainer }}
              >
                <Grid item classes={{ root: classes.textContainer }}>
                  <Typography
                    variant="body1"
                    classes={{ root: classes.itemHeading }}
                  >
                    {"Public Holiday Surcharge"}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant="body1" classes={{ root: classes.price }}>
                    {priceFormat(surchargeFee.phSurchargeFee)}
                  </Typography>
                </Grid>
              </Grid>
            ) : (
              <></>
            )}
            {surchargeFee.sunSurchargeFee > 0 ||
            surchargeFee.satSurchargeFee > 0 ? (
              <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                wrap="nowrap"
                classes={{ root: classes.mainContainer }}
              >
                <Grid item classes={{ root: classes.textContainer }}>
                  <Typography
                    variant="body1"
                    classes={{ root: classes.itemHeading }}
                  >
                    {"Weekend Surcharge"}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant="body1" classes={{ root: classes.price }}>
                    {priceFormat(
                      surchargeFee.sunSurchargeFee > 0
                        ? surchargeFee.sunSurchargeFee
                        : surchargeFee.satSurchargeFee
                    )}
                  </Typography>
                </Grid>
              </Grid>
            ) : (
              <></>
            )}
          </>
        ) : (
          <></>
        )}

        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          wrap="nowrap"
          classes={{ root: classes.mainContainer }}
        >
          <Grid item classes={{ root: classes.textContainer }}>
            <Typography
              variant="body1"
              classes={{ root: classes.itemHeadingBold }}
            >
              {"Total"}
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="body1" classes={{ root: classes.priceBold }}>
              {priceFormat(
                parseFloat(
                  (
                    subTotal +
                    fee +
                    surchargeFee.phSurchargeFee +
                    surchargeFee.sunSurchargeFee +
                    surchargeFee.satSurchargeFee
                  ).toFixed(2)
                )
              )}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}

export default CartServiceItem
