import React, { useEffect, useState } from "react"
import axios from "axios"
import { PaymentElement, useStripe, useElements } from "@stripe/react-stripe-js"
import { useForm } from "react-hook-form"
import { navigate } from "gatsby"
import Card from "@material-ui/core/Card"
import CardContent from "@material-ui/core/CardContent"
import Backdrop from "@material-ui/core/Backdrop"
import Typography from "@material-ui/core/Typography"
import CardActions from "@material-ui/core/CardActions"
import Grid from "@material-ui/core/Grid"
import Box from "@material-ui/core/Box"
import Button from "@material-ui/core/Button"
import Chip from "@material-ui/core/Chip"
import Container from "@material-ui/core/Container"
import AccountDetailsFields from "./AccountDetailsFields"
import SavedCards from "./SavedCards"
import CheckoutDone from "./CheckoutDone"
import handleError from "../../helperFunc/handleError"
import CartFooter from "../../components/ui/stickyFooter"
import priceFormat from "../../helperFunc/priceFormat"
import Alert from "@material-ui/lab/Alert"
import Switch from "@material-ui/core/Switch"
import FormGroup from "@material-ui/core/FormGroup"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import { v4 as uuidv4 } from "uuid"

import { setUser, clearCart, setOrderOptions } from "../../contexts/actions"

import CircularProgress from "@material-ui/core/CircularProgress"

import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles(theme => ({
  circularProgressCont: {
    minWidth: "4rem",
  },
  circularProgress: {
    verticalAlign: "middle",
  },
  mainIcon: {
    verticalAlign: "sub",
    fontSize: "2rem !important",
    marginRight: "0.5rem",
  },

  inMainContainer: {
    paddingLeft: 0,
    paddingRight: 0,
    marginTop: "4.0rem",
    marginBottom: "5rem",
    maxWidth: "100%",
    [theme.breakpoints.up("md")]: {
      maxWidth: "40rem",
      marginTop: "6.0rem",
      marginBottom: "2rem",
    },
  },
  boxGrids: {
    padding: 0,
  },
  mainCard: {
    borderRadius: 0,
    [theme.breakpoints.up("md")]: {
      borderRadius: "1rem",
    },
  },
  titleArea: {
    backgroundColor: theme.palette.common.darkGrey,
    paddingTop: "0.5rem",
    paddingBottom: "0.5rem !important",
  },
  titleText: {
    textTransform: "uppercase",
    fontSize: "1.5rem",
    paddingLeft: "0.5rem",
    paddingRight: "0.5rem",
    color: theme.palette.common.white,
  },

  contentArea: {
    paddingLeft: "1.5rem",
    paddingRight: "1.5rem",
    backgroundColor: theme.palette.common.white,
  },

  bottomArea: {
    backgroundColor: theme.palette.common.offWhite,
    height: "0px",
    padding: "0px",
  },

  innerContainer: {
    width: "100%",
  },
  mainContainer: {
    minHeight: "calc(100vh  - 100px)",

    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  },

  butDisabled: {
    height: "3rem !important",
    backgroundColor: `${theme.palette.common.seventyWhite} !important`,
    color: `${theme.palette.common.white} !important`,
  },

  butChip: {
    backgroundColor: theme.palette.common.white,

    marginLeft: "1rem",
    cursor: "pointer",
  },

  price: { fontSize: "0.9rem", lineHeight: "1.1rem" },

  butText: {
    fontSize: "1.2rem",
  },
  butTextNoChip: {
    fontSize: "1.2rem",
    paddingRight: "0.875rem",
  },
  but: {
    height: "3rem",
    pointerEvents: "auto",
    paddingRight: "0.5rem",
    borderRadius: "10rem",
  },
  backdrop: {
    zIndex: `${theme.zIndex.drawer + 1}`,
  },

  subTitle: {
    fontSize: "1.2rem",
    marginBottom: "0.75rem",
  },
  saveCardSwitch: {
    marginTop: "0.5rem",
  },
  alert: {
    marginTop: "1rem",
    marginBottom: "1rem",
  },

  "@global": {
    body: {
      [theme.breakpoints.down("sm")]: {
        backgroundColor: `${theme.palette.common.white} !important`,
      },
    },
  },
}))

export default function CheckoutForm({
  clientSecret,
  order,
  user,
  dispatchUser,
  dispatchFeedback,
  storeSettings,
  dispatchCart,
  dispatchOrderOptions,
  setClientSecret,
  feedback,
  existingCards,
}) {
  const classes = useStyles()
  const stripe = useStripe()
  const elements = useElements()

  const [message, setMessage] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [isPaymentValid, setIsPaymentValid] = useState(false)
  const [isOrderComplete, setIsOrderComplete] = useState(false)
  const [paymentType, setPaymentType] = useState("")
  const [isSaveCard, setIsSaveCard] = useState(false)
  const [selectedCardId, setSelectedCardId] = useState("noCard")

  const { control, formState, watch } = useForm({
    mode: "onChange",
  })
  const { isValid } = formState
  let { name, receiptEmail } = watch()

  let defaultOrderOptions = {
    totalOrderPrice: 0,
    serviceFee: 0,
    surchargeFee: { phSurchargeFee: 0, sunSurchargeFee: 0, satSurchargeFee: 0 },
    surchargeRate: {
      phSurchargeRate: 0,
      sunSurchargeRate: 0,
      satSurchargeRate: 0,
    },
    tax: 0,
    orderType: storeSettings.isTakeAwayClosed ? null : "takeAway",
    orderTableNumber: null,
    orderPickUpDate: null,
    orderPickUpTime: null,
    orderNotes: "",
    lastUpdated: 0,
  }

  useEffect(() => {
    if (!stripe) {
      return
    }
    if (!clientSecret) {
      return
    }
  }, [stripe, clientSecret])

  const confirmPaymentFunc = async () => {
    let result
    if (
      existingCards.length > 0 &&
      selectedCardId !== "newCard" &&
      selectedCardId !== "noCard"
    ) {
      result = await stripe.confirmCardPayment(clientSecret, {
        payment_method: selectedCardId,
      })
    } else {
      result = await stripe.confirmPayment({
        elements,
        redirect: "if_required",
        confirmParams: {
          payment_method_data: {
            billing_details: {
              address: {
                country: "AU",
              },
            },
          },
        },
      })
    }

    if (result.error) {
      if (
        result.error.type === "card_error" ||
        result.error.type === "validation_error"
      ) {
        setMessage(result.error.message)
      } else {
        setMessage("An unexpected error occurred.")
      }
      setIsLoading(false)
    } else if (result.paymentIntent.status === "succeeded") {
      dispatchCart(clearCart())
      localStorage.removeItem("orderOptions")
      localStorage.removeItem("intentID")
      dispatchOrderOptions(setOrderOptions(defaultOrderOptions))
      setIsLoading(false)
      setIsOrderComplete(true)
    }
  }

  const handleSubmit = async e => {
    e.preventDefault()

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return
    }
    setIsLoading(true)
    if (receiptEmail !== user.receiptEmail || name !== user.userDisplayName) {
      axios
        .post(
          process.env.GATSBY_STRAPI_URL + "/api/users/update-by-phone",
          {
            userDisplayName: name.replace(/^\s+|\s+$/gm, ""),
            receiptEmail: receiptEmail.replace(/^\s+|\s+$/gm, ""),
          },
          {
            headers: {
              Authorization: `Bearer ${user.jwt}`,
            },
          }
        )
        .then(response => {
          dispatchUser(
            setUser({
              ...response.data,
              jwt: user.jwt,
              onboarding: true,
            })
          )
          confirmPaymentFunc()
        })
        .catch(error => {
          handleError(error, dispatchFeedback)
          setIsLoading(false)
        })
    } else {
      confirmPaymentFunc()
    }
  }

  const handleChangeIntent = async toSaveCard => {
    setIsLoading(true)
    if (order) {
      const storedIntent = localStorage.getItem("intentID")
      const idempotencyKey = uuidv4()

      axios
        .post(
          process.env.GATSBY_STRAPI_URL + "/api/orders/process",
          {
            orderId: order.id,
            total: order.total,
            idempotencyKey: idempotencyKey,
            storedIntent: storedIntent,
            saveCard: toSaveCard,
          },
          {
            headers: {
              Authorization: `Bearer ${user.jwt}`,
            },
          }
        )
        .then(response => {
          setIsLoading(false)
          setClientSecret(response.data.client_secret)
          localStorage.setItem("intentID", response.data.intentID)
        })
        .catch(error => {
          setIsLoading(false)
          handleError(error, dispatchFeedback)
        })
    }
  }

  const handlePaymentChange = async e => {
    setPaymentType(e.value?.type)

    if (e.value?.type !== "card" && isSaveCard) {
      setIsSaveCard(false)
      handleChangeIntent(false)
    }

    if (e.complete) {
      setIsPaymentValid(true)
    } else {
      setIsPaymentValid(false)
    }
  }

  const handleSaveCardChange = async e => {
    setIsSaveCard(e.target.checked)
    handleChangeIntent(e.target.checked)
  }

  const fields = {
    billingDetails: {
      address: {
        country: "never",
        postalCode: "auto",
      },
    },
  }

  const options = {
    fields,
  }

  return (
    <>
      <form id="stripe-payment-inp-form">
        <Grid
          container
          justifyContent="center"
          alignItems="flex-start"
          classes={{ root: classes.mainContainer }}
        >
          <Grid item classes={{ root: classes.innerContainer }}>
            <Grid
              container
              direction="column"
              justifyContent="center"
              alignItems="center"
            >
              <Container classes={{ root: classes.inMainContainer }}>
                <Grid item classes={{ root: classes.boxGrids }}>
                  <Card classes={{ root: classes.mainCard }} elevation={0}>
                    <CardContent classes={{ root: classes.titleArea }}>
                      <Typography
                        variant="h6"
                        classes={{ root: classes.titleText }}
                      >
                        {"Checkout"}
                      </Typography>
                    </CardContent>
                    <CardActions
                      disableSpacing
                      classes={{ root: classes.bottomArea }}
                    ></CardActions>

                    <CardContent classes={{ root: classes.contentArea }}>
                      {isOrderComplete ? (
                        <CheckoutDone order={order} user={user} />
                      ) : (
                        <>
                          <Typography
                            variant="h6"
                            classes={{ root: classes.subTitle }}
                          >
                            {"Your details"}
                          </Typography>
                          <AccountDetailsFields
                            user={user}
                            control={control}
                          ></AccountDetailsFields>
                          {existingCards.length > 0 ? (
                            <SavedCards
                              existingCards={existingCards}
                              selectedCardId={selectedCardId}
                              setSelectedCardId={setSelectedCardId}
                            ></SavedCards>
                          ) : (
                            <></>
                          )}

                          {existingCards.length === 0 ? (
                            <Typography
                              variant="h6"
                              classes={{ root: classes.subTitle }}
                            >
                              {"Payment details"}
                            </Typography>
                          ) : (
                            <></>
                          )}
                          {(existingCards.length > 0 &&
                            selectedCardId === "newCard") ||
                          existingCards.length === 0 ? (
                            <>
                              <PaymentElement
                                id="payment-element"
                                options={options}
                                onChange={handlePaymentChange}
                              />
                              {paymentType === "card" ? (
                                <FormGroup>
                                  <FormControlLabel
                                    classes={{
                                      root: classes.saveCardSwitch,
                                    }}
                                    control={
                                      <Switch
                                        disabled={existingCards.length > 5}
                                        checked={isSaveCard}
                                        onChange={handleSaveCardChange}
                                        name="isSaveCard"
                                      />
                                    }
                                    label={`Securely save your card ${
                                      existingCards.length > 5
                                        ? "(maximum of 6 saved cards)."
                                        : "details."
                                    }`}
                                  />
                                </FormGroup>
                              ) : (
                                <></>
                              )}
                            </>
                          ) : (
                            <></>
                          )}

                          {message && (
                            <Alert
                              severity="error"
                              classes={{ root: classes.alert }}
                            >
                              <Typography variant={"body1"}>
                                {message}
                              </Typography>
                            </Alert>
                          )}
                        </>
                      )}
                    </CardContent>
                  </Card>
                </Grid>
              </Container>
            </Grid>
          </Grid>
        </Grid>
      </form>
      <CartFooter>
        <Button
          variant="contained"
          size="large"
          color="primary"
          classes={{ root: classes.but, disabled: classes.butDisabled }}
          disableElevation
          onClick={
            isOrderComplete
              ? e => {
                  navigate("/menu/")
                }
              : handleSubmit
          }
          disabled={
            isLoading ||
            !stripe ||
            !elements ||
            !order ||
            (!isPaymentValid &&
              ((existingCards.length > 0 && selectedCardId === "newCard") ||
                existingCards.length === 0)) ||
            !isValid
          }
        >
          {isLoading ? (
            <Box classes={{ root: classes.circularProgressCont }}>
              <CircularProgress
                size="1.5rem"
                classes={{ root: classes.circularProgress }}
              />
            </Box>
          ) : isOrderComplete ? (
            <Typography
              variant="body1"
              classes={{ root: classes.butTextNoChip }}
            >
              {"Return to Menu"}
            </Typography>
          ) : (
            <Typography variant="body1" classes={{ root: classes.butText }}>
              {"Pay now"}
            </Typography>
          )}
          {!isOrderComplete ? (
            <Chip
              label={
                <Typography variant="body1" classes={{ root: classes.price }}>
                  {priceFormat(order.total)}
                </Typography>
              }
              classes={{ root: classes.butChip }}
            ></Chip>
          ) : (
            <></>
          )}
        </Button>
      </CartFooter>
      <Backdrop open={isLoading} classes={{ root: classes.backdrop }}>
        <CircularProgress color="secondary" />
      </Backdrop>
    </>
  )
}
